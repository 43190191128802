<template>
  <v-container>
    <v-row>
      <v-col>
        <go-back-btn :routeToGo="routeToGo" />
        <PageHeader :title="title" class="mb-3" />
        <!-- Data table -->
        <v-card>
          <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="itemsFeriados"
            :loading="loading"
            no-data-text="No hay datos disponibles para mostrar"
            :search="searchConsulta"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10" align-self="center" class="pt-5">
                    <v-text-field
                      v-model="searchConsulta"
                      clearable
                      label="Buscar"
                      single-line
                      :append-icon="iconoSearch"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" class="pt-5">
                    <v-btn
                      v-if="canCreateNewFeriado"
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip v-if="canEditFeriado" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar feriado</span>
              </v-tooltip>
              <v-tooltip v-if="canDeleteFeriado" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteFeriado(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar feriado</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalFeriado"
      v-model="openModalFeriado"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditFeriados
        :registrosFeriados="registrosFeriados"
        @closeAndReload="closeAndReload"
      ></EditFeriados>
    </v-dialog>
    <DeleteDialog
      max-width="25%"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import enumsAdmin from "@/utils/enums/modules/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditFeriados from "@/views/EditFeriados.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn, EditFeriados, DeleteDialog },
  name: "Feriados",
  data() {
    return {
      titleDelete: "¿Eliminar feriado?",
      showDeleteModal: false,
      loading: false,
      routeToGo: "/",
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      searchConsulta: null,
      iconoSearch: enums.icons.SEARCH_ICON,
      openModalFeriado: false,
      itemsFeriados: [],
      title: "Feriados",
      idToDelete: null,
      registrosFeriados: {},
      canCreateNewFeriado: false,
      canEditFeriado: false,
      canDeleteFeriado: false,
      headers: [
        { text: "Fecha", sortable: false, value: "fecha" },
        {
          text: "Descripción",
          align: "center",
          sortable: false,
          value: "descripcion",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  async created() {
    this.setPermisos();
    this.loadFeriados();
  },
  methods: {
    ...mapActions({
      fetchFeriados: "clientesData/fetchFeriados",
      deleteFeriados: "clientesData/deleteFeriados",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.adminSistema.permissions.NUEVO_FERIADO:
            this.canCreateNewFeriado = true;
            break;
          case enumsAdmin.adminSistema.permissions.EDIT_FERIADO:
            this.canEditFeriado = true;
            break;
          case enumsAdmin.adminSistema.permissions.DELETE_FERIADO:
            this.canDeleteFeriado = true;
            break;
          default:
            break;
        }
      });
    },
    openModal(item) {
      this.openModalFeriado = true;
      this.registrosFeriados = item;
    },
    closeAndReload() {
      this.openModalFeriado = false;
      this.loadFeriados();
    },
    async loadFeriados() {
      this.loading = true;
      const response = await this.fetchFeriados();
      this.itemsFeriados = response;
      this.loading = false;
    },
    deleteFeriado(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.feriadoId;
    },
    async confirmDelete() {
      const response = await this.deleteFeriados(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadFeriados();
      }
    },
  },
};
</script>
<style>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

.v-select__selection--disabled input {
  color: black !important;
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
#fechaChildFeriados {
  color: rgba(0, 0, 0, 0.765) !important;
}
</style>
