<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                ref="fechaAlta"
                v-model="menuFechaFeriado"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedfechaFeriadoFormatted"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    @click:append="menuFechaFeriado = !menuFechaFeriado"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    id="fechaChildFeriados"
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="
                      fechaFeriado = parseDateToIso(
                        computedfechaFeriadoFormatted
                      )
                    "
                    @input="
                      fechaFeriado = parseDateToIso(
                        computedfechaFeriadoFormatted
                      )
                    "
                    @change="
                      (fechaFeriado = parseDateToIso(
                        computedfechaFeriadoFormatted
                      )),
                        (menuFechaFeriado = false)
                    "
                    :rules="rules.required.concat(rules.validDate)"
                    dense
                    outlined
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFeriado"
                  @change="
                    computedfechaFeriadoFormatted = formatDate(fechaFeriado)
                  "
                  no-title
                  scrollable
                  @input="menuFechaFeriado = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- Tipo de texto -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                tabindex="2"
                v-model="descripcion"
                :rules="rules.required.concat([rules.maxLength(descripcion, 100)])"
                label="Descripción"
                dense
                auto-grow
                rows="2"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
export default {
  name: "EditFeriados",
  props: {
    registrosFeriados: {
      Type: Object,
      required: false,
    },
  },
  data: () => ({
    formEditTitle: "",
    menuFechaFeriado: null,
    calendarIcon: enums.icons.CALENDAR_ICON,
    computedfechaFeriadoFormatted: null,
    fechaFeriado: null,
    rules: rules,
    isFormValid: false,
    descripcion: null,
    loadingSaveBtn: false,
  }),
  created() {
    //trae los Tipos de usuarios en el select
    if (this.registrosFeriados) {
      this.setFeriados();
    } else {
      this.newFeriados();
    }
  },
  methods: {
    ...mapActions({
      postFeriados: "clientesData/postFeriados",
      setAlert: "user/setAlert",
    }),
    newFeriados() {
      this.formEditTitle = "Nuevo feriado";
    },
    async setFeriados() {
      this.formEditTitle = "Editar feriado";
      this.computedfechaFeriadoFormatted = this.registrosFeriados.fecha;
      this.descripcion = this.registrosFeriados.descripcion;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
      feriadoId: this.registrosFeriados?.feriadoId,
        fecha:  this.parseDateToIso(this.computedfechaFeriadoFormatted),
        descripcion: this.descripcion,
        // hsFin: null,
        // hsInicial: null,
      };
      try {
        const response = await this.postFeriados(data);
        if (response.status === 200) {
          this.loadingSaveBtn = false;
          this.closeModal();
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.loadingSaveBtn = false;
        }
      } catch {
        this.loadingSaveBtn = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped></style>
